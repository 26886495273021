import React, { useEffect } from 'react';
import ButtonIcon from '../../assets/icons/button-icon.svg';
import StreamIcon from '../../assets/icons/stream.svg';
import FanbaseIcon from '../../assets/icons/fanbase.svg';
import BestTrack from '../../assets/icons/best-track.svg';
import Listeners from '../../assets/icons/listeners.svg';
import FollowersIcon from '../../assets/icons/followers.svg';
import AreaChart from './charts/AreaChart';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../../redux/actions/index';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Dashboard = () => {
    const location = useLocation();
    const data = location.state;
    const dispatch = useDispatch();
    const artistDetails = useSelector(state => state.artistDetails);
    const { artistInfo } = artistDetails;
        return (
        <div className='dashboard-page py-5'>
            <ToastContainer />
            <div className="container max-w-full">
                <div className="card bg-gradient-to-r from-theme_clr1 to-theme_clr2 rounded-xl mb-5 p-4 lg:p-5 pb-9">
                    <h5 className='text-white text-[23px] font-manrope font-bold mb-4'>You’re in control of your journey, {artistInfo.name}.</h5>
                    <div className="input-wrapper flex flex-wrap gap-[11px]">
                        <input type="text" className='flex-1 bg-transparent text-white placeholder:text-white text-base font-jakarta font-medium border border-white rounded-full px-5 py-3' placeholder='Ask your AI Assistant, Amplitude for help.' required />
                        <button className='btn-custom2 w-[192px] flex items-center justify-center gap-5 shadow-lg'>Submit <img src={ButtonIcon} alt="Button Icon" /></button>
                    </div>
                </div>

                {/* Performance Section */}
                <div className="performance-section bg-theme_clr1 rounded-xl mb-5 p-4 lg:px-5 pt-[26px] pb-[30px]">
                    <div className="header-section flex flex-wrap justify-between gap-2 mb-[10px]">
                        <select name="" id="" className='bg-transparent text-white text-base font-manrope border-0 focus:outline-none'>
                            <option value="0">All time performance</option>
                            <option value="1">Monthly performance</option>
                            <option value="2">Quarterly performance</option>
                            <option value="3">Yearly performance</option>
                        </select>
                        <button type='button' className='btn-custom3'>View Full Analytics</button>
                    </div>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 2xl:gap-[22px] flex-nowrap overflow-auto">
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={StreamIcon} alt="Streams" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">10.3M</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Streams</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={FanbaseIcon} alt="Fanbase" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">50.0k</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Fanbase</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon">
                                    <img src={BestTrack} alt="Best Track" />
                                    <div className="text-black text-[12.26px] font-bold mt-2">Right Now V2</div>
                                </div>
                                <div className="chart-wrapper">
                                    {/* <AreaChart /> */}
                                    <img src={require('../../assets/images/best-track2.jpg')} alt="Best Track" className='block w-[400px] mx-auto' />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">7.3M</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Best Performing Track</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={Listeners} alt="Listeners" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">4.4M</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Listeners</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item sm:col-span-2 xl:col-span-1">
                            <div className="info-box h-full bg-white font-manrope flex flex-col justify-between gap-2 rounded-xl p-4 2xl:p-5">
                                <div className="icon mb-1">
                                    <img src={FollowersIcon} alt="FollowersIcon" />
                                </div>
                                <div className="chart-wrapper" style={{ marginLeft: '-10px' }}>
                                    <AreaChart />
                                </div>
                                <div className="cover">
                                    <div className="text-theme_clr1 text-[12.26px] font-bold mb-[2px]">15.0k</div>
                                    <div className="text-theme_clr1 text-[12.8px]">Followers</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Grid Listing Section */}
                <div className="performance-section">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white font-manrope flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info mb-3">
                                    <div className="text-[21.56px] font-bold mb-4">Learn with ProfessorU</div>
                                    <div className="text-[12.5px]">Access tutorials, guides, and expert tips to grow your music career.</div>
                                </div>
                                <div className="icon text-center mb-3">
                                    <img src={require('../../assets/icons/ProfessorU.png')} alt="ProfessorU" className='block max-w-[110px] mx-auto' />
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='text-white text-xs font-bold bg-gradient-to-b from-theme_clr2 from-[73%] to-[#FFB700] rounded-lg leading-[22.4px] px-4 py-2'>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white font-manrope flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info mb-3">
                                    <div className="text-[21.56px] font-bold mb-4">Create a Smartlink</div>
                                    <div className="text-[12.5px]">Generate a mini-site to share your music in minutes.</div>
                                </div>
                                <div className="icon text-center mb-3">
                                    <img src={require('../../assets/icons/Fanlink.png')} alt="Fanlink" className='block max-w-[166px] mx-auto' />
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='text-white text-xs font-bold bg-gradient-to-b from-theme_clr2 from-[73%] to-[#FFB700] rounded-lg leading-[22.4px] px-4 py-2'>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white font-manrope flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info mb-3">
                                    <div className="text-[21.56px] font-bold mb-4">Build your EPK</div>
                                    <div className="text-[12.5px]">Create a professional Electronic Press Kit to showcase your achievements.</div>
                                </div>
                                <div className="icon text-center mb-3">
                                    <img src={require('../../assets/icons/Press-Kit.png')} alt="Press-Kit" className='block max-w-[72px] mx-auto' />
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='text-white text-xs font-bold bg-gradient-to-b from-theme_clr2 from-[73%] to-[#FFB700] rounded-lg leading-[22.4px] px-4 py-2'>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white font-manrope flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info mb-3">
                                    <div className="text-[21.56px] font-bold mb-4">Exclusives for You </div>
                                    <div className="text-[12.5px]">Unlock special offers, tools, and resources tailored for you.</div>
                                </div>
                                <div className="icon text-center mb-3">
                                    <img src={require('../../assets/icons/Exclusives.png')} alt="Exclusives" className='block max-w-[125px] mx-auto' />
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='text-white text-xs font-bold bg-gradient-to-b from-theme_clr2 from-[73%] to-[#FFB700] rounded-lg leading-[22.4px] px-4 py-2'>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white font-manrope flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info mb-3">
                                    <div className="text-[21.56px] font-bold mb-4">Fan Data</div>
                                    <div className="text-[12.5px]">Understand your audience for better engagement.</div>
                                </div>
                                <div className="icon text-center mb-3">
                                    <img src={require('../../assets/icons/Fan-Data.png')} alt="Fan Data" className='block max-w-[70px] mx-auto' />
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='text-white text-xs font-bold bg-gradient-to-b from-theme_clr2 from-[73%] to-[#FFB700] rounded-lg leading-[22.4px] px-4 py-2'>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="info-box h-full bg-theme_clr1 text-white font-manrope flex flex-col justify-between rounded-xl p-4 xl:p-5">
                                <div className="title-info mb-3">
                                    <div className="text-[21.56px] font-bold mb-4">Release Planner</div>
                                    <div className="text-[12.5px]">Organize and schedule your upcoming releases for maximum impact.</div>
                                </div>
                                <div className="icon text-center mb-3">
                                    <img src={require('../../assets/icons/Release-Planner.png')} alt="Release Planner" className='block max-w-[65px] mx-auto' />
                                </div>
                                <div className="btn-wrapper">
                                    <button type='button' className='text-white text-xs font-bold bg-gradient-to-b from-theme_clr2 from-[73%] to-[#FFB700] rounded-lg leading-[22.4px] px-4 py-2'>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item order-first xl:order-last sm:col-span-2 lg:col-span-3 xl:row-start-1 xl:row-end-3 xl:col-start-4">
                            <div className="info-box h-full bg-theme_clr1 text-white font-manrope rounded-xl p-4 xl:p-5">
                                <div className="text-[21.56px] font-bold mb-4">Quick Actions</div>
                                <div className="btn-wrapper flex flex-wrap xl:flex-col gap-3 xl:gap-4">
                                    <button type='button' className='btn-custom3 !py-3'>Create New Campaign</button>
                                    <button type='button' className='btn-custom3 !py-3'>Submit to Playlist</button>
                                    <button type='button' className='btn-custom3 !py-3'>Master a Track</button>
                                    <button type='button' className='btn-custom3 !py-3'>Schedule Content</button>
                                    <button type='button' className='btn-custom3 !py-3'>Estimate Revenue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
