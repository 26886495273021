import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AuthBackground from '../assets/images/auth-banner.jpg';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../redux/actions/auth';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const auth = useSelector(state => state.auth);
  const { loading } = auth;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if (email && password) {
      const data = {
        emailOrUsername: email, password
      }
      dispatch(actions.login(data, navigate, toast));
    } else {
      toast.warning('Please enter email and password', {
        position: "top-center",
        theme: "colored",
        hideProgressBar: true
      })
    }
  }


  return (
    <div className='auth-page h-screen flex items-center justify-center'>
      <ToastContainer />
      <div className="grid grid-cols-1 md:grid-cols-2 h-full w-full">
        <div className="grid-item">
          <div className="left-sec bg-theme_clr1 h-full flex items-center justify-center text-center px-5 py-12 lg:p-[50px] 2xl:p-[69px]">
            <form action="" className='font-manrope w-full max-w-[600px] mx-auto'>
              <div type='button' className="logo-wrapper mb-6 md:mb-7 xl:mb-[33px] cursor-pointer" onClick={()=>navigate('/')}>
                <img src={require('../assets/images/logo.png')} alt="Logo" className="logo max-w-[145px] 2xl:max-w-[200px] mx-auto" />
                <div className='line-bar bg-[#EDECF2] opacity-[15%] h-[1px] w-[64px] mx-auto'></div>
              </div>
              <div className='heading text-white text-xl lg:text-[25px] xl:text-[32px] 2xl:text-[54px] font-medium mb-5 md:mb-6 xl:mb-8 leading-tight'>Welcome back!</div>
              <button type='button' className='w-full bg-white text-black flex items-center justify-center gap-[4.5px] rounded-full mb-3 2xl:mb-5 px-5 py-[5px] lg:py-[6px] 2xl:py-[10px]'>
                <img src={require('../assets/icons/google-icon.png')} alt="Google Icon" className='h-[33px] w-[33px]' /> Login with Google
              </button>
              <button type='button' className='w-full bg-white text-black flex items-center justify-center gap-[4.5px] rounded-full mb-3 2xl:mb-5 px-5 py-[5px] lg:py-[6px] 2xl:py-[10px]'>
                <img src={require('../assets/icons/facebook-icon.png')} alt="Facebook Icon" className='h-[33px] w-[33px]' /> Login with Facebook
              </button>
              <button type='button' className='w-full bg-white text-black flex items-center justify-center gap-[4.5px] rounded-full mb-8 px-5 py-[5px] lg:py-[6px] 2xl:py-[10px]'>
                <img src={require('../assets/icons/apple-icon.png')} alt="Apple Icon" className='h-[33px] w-[33px]' />Login with Apple
              </button>
              <div className="divider-wrapper relative mb-8">
                <span className="divider-line block bg-[#EDECF2] opacity-[32%] h-[1px] w-full"></span>
                <span className='absolute bg-theme_clr1 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-nowrap px-1' style={{ color: 'rgba(255, 255, 255, 0.72)' }}>Or login with your email</span>
              </div>
              <div className="input-cover mb-[18px]">
                <input type="email" value={email} className='form-control' placeholder='Email' autoComplete='off' required onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="input-cover mb-5">
                <div className="relative">
                  <input type={passwordVisible ? 'text' : 'password'} value={password} className='form-control' placeholder='Password' autoComplete='new-password' required onChange={(e) => setPassword(e.target.value)} />
                  <span role="button" className="text-white absolute top-1/2 right-4 -translate-y-1/2 text-secondary" onClick={togglePasswordVisibility}>
                    {passwordVisible ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}
                  </span>
                </div>
                <Link to={'/forgot-password'} className='block w-full text-white text-base text-left mt-2'>Forgot your password?</Link>
              </div>


              <button type='submit' className='loader-button w-full bg-theme_clr2 text-white font-medium flex items-center justify-center gap-[4.5px] rounded-full mb-5 px-5 py-[11.5px]' disabled={loading} onClick={(e) => handleSubmit(e)}>
                {loading ? (
                  <span className="loader"> </span>
                ) : (
                  ''
                )}
                Log in
              </button>
              <div className="text-white text-base xl:text-lg flex flex-wrap items-center justify-center gap-3 opacity-[70%]">
                Don't have an account? <Link to={'/signup'} className='text-theme_clr2'>Sign up</Link>
              </div>
            </form>
          </div>
        </div>
        <div className="grid-item">
          <div className="right-sec h-full flex items-center justify-center px-5 py-12 lg:p-[50px] 2xl:p-[69px]" style={{ backgroundImage: `url(${AuthBackground})` }}>
            <div className="overlay-wrapper bg-[#ffffff] opacity-[72%] max-w-[500px] font-manrope rounded-3xl mx-auto p-5 lg:p-6 xl:p-8">
              <div className="flex flex-wrap items-center justify-between mb-4">
                <div className="left">
                  <h5 className='text-base xl:text-lg 2xl:text-xl text-[#707070]'>Over</h5>
                  <div className="counter text-[40px] xl:text-[50px] 2xl:text-[67px] font-extrabold leading-tight">3M</div>
                  <div className="stream-on text-base xl:text-lg 2xl:text-xl text-[#707070]">streams on Spotify</div>
                </div>
                <div className="right">
                  <div className='rounded-3xl p-5 lg:p-6' style={{ backgroundColor: 'rgba(30, 215, 96, 0.16)' }}>
                    <img src={require('../assets/icons/spotify-icon.png')} alt="Spotify Icon" className='block w-[50px] xl:w-[63px]' />
                  </div>
                </div>
              </div>

              <div className="text-black text-base xl:text-lg 2xl:text-xl font-jakarta mb-6 xl:mb-8">
                "What I love most about UpNComer is the clarity it provides. I can track my progress across campaigns,
                finances, and streams—all in one place. It’s like having a full music team at my fingertips, but with more autonomy."
              </div>
              <div className="author-section flex items-center gap-4">
                <img src={require('../assets/images/karan.png')} alt="Author" className='block w-[60px] 2xl:w-[88px]' />
                <div className="author-info capitalize">
                  <div className="name text-lg xl:text-xl 2xl:text-[22px] xl:mb-1">Karan</div>
                  <div className="title text-base xl:text-lg 2xl:text-xl text-[#707070]">Artist</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
