import React from 'react';
import Chart from 'react-apexcharts';

const AreaChart = () => {
    const series = [
        {
            name: 'Sales',
            data: [30, 45, 60, 75, 85, 70, 85, 90, 110],
        },
    ];

    const options = {
        chart: {
            type: 'area',
            height: '180',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,  // Disable zoom
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            show: true, // Hide the area line
            width: '2px',
            colors: ['#00067E'],
        },
        xaxis: {
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
            ],
            labels: {
                show: false, // Hide category labels
            },
            axisBorder: {
                show: false,      // Hide X-axis ticks
            },
            axisTicks: {
                show: false,      // Hide X-axis ticks
            },
        },
        yaxis: {
            labels: {
                enabled: false,
                show: false, // Hide y-axis labels if needed
            },
        },
        fill: {
            opacity: 0.5,
        },
        grid: {
            show: false, // Remove background grid lines
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    };

    return (
        <Chart options={options} series={series} type="area" height={180} />
    );
};

export default AreaChart;
