const initialState = {
    loading: null,
    token: '',
    userDetails: {},
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTHSTART':
            return {
                ...state,
                isLoggedIn: false,
                loading: true,
            };
        case 'AUTHFAIL':
            return {
                ...state,
                loading: null,
            };
        case 'AUTHSUCCESS':
            return {
                ...state,
                loading: null,
            };
        case 'SIGNUP':
            return {
                ...state,
                loading: null,
            };
        case 'LOGIN':
            return {
                ...state,
                isLoggedIn: true,
                loading: null,
                token: action.token,
                userDetails: action.details
            };
        case 'LOGOUT':
            return {
                ...state,
                isLoggedIn: false,
                loading: null,
            };
        default:
            return state;
    }
};

export default auth;