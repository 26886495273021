import React from 'react';

export const TermsOfServices = () => {
  return (
    <div className='TermsOfServices-page py-7 md:py-9'>
      <div className="container" style={{ maxWidth: '928px' }}>
        <div className='page-heading text-theme_clr1 text-[25px] xl:text-[32px] font-spline font-bold leading-tight mb-4 md:mb-7'>Terms of Service</div>
        <div className="search-wrapper relative mb-5 md:mb-7">
          <span className='search-icon absolute start-[18px] top-1/2 -translate-y-1/2 z-10'>
            <i className="bi bi-search text-white text-xl"></i>
          </span>
          <input type="search" className='w-full bg-theme_clr2 text-white text-base font-spline placeholder:text-white focus:outline-none rounded-xl pl-12 pr-4 py-3' placeholder='Search for terms or topics' />
        </div>
        <div className="info-wrapper flex flex-col gap-6 md:gap-8 font-spline">
          <div className="info-box">
            <h2 className="text-theme_clr1 text-xl md:text-[22px] font-bold mb-4">1. Acceptance of Terms</h2>
            <p>This is an agreement between you and UpNComer. This agreement governs your use of the UpNComer service.</p>
          </div>
          <div className="info-box">
            <h2 className="text-theme_clr1 text-xl md:text-[22px] font-bold mb-4">2. User Responsibilities</h2>
            <p>You are responsible for all activity that occurs under your account. You may not use the service for any illegal or unauthorized purpose. You must provide a valid email address in order to complete the signup process.</p>
          </div>
          <div className="info-box">
            <h2 className="text-theme_clr1 text-xl md:text-[22px] font-bold mb-4">3. Payment Terms</h2>
            <p>You agree to pay the fees for the services that you purchase, and you authorize us to charge your debit card, credit card, or other payment method. The service is billed in advance on a monthly or annual basis and is non-refundable. There will be no refunds or credits for partial months of service.</p>
          </div>
          <div className="info-box">
            <h2 className="text-theme_clr1 text-xl md:text-[22px] font-bold mb-4">4. Termination of Use</h2>
            <p>We may terminate your access to all or any part of the service at any time, with or without cause, with or without notice, effective immediately.</p>
          </div>
          <div className="info-box">
            <h2 className="text-theme_clr1 text-xl md:text-[22px] font-bold mb-4">5. Privacy and Security</h2>
            <p>We take privacy and security seriously and we will do our best to protect your data.</p>
          </div>
          <div className="info-box">
            <h2 className="text-theme_clr1 text-xl md:text-[22px] font-bold mb-4">6. Changes to Terms</h2>
            <p>We may change the terms from time to time. If we make changes, we will provide notice of the changes, such as by sending an email, providing a notice through the service, or updating the date at the top of the terms. The changes will not apply retroactively.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
