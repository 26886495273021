const initialState = {
    loading: null,
    artistInfo:{}
};

const artistDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'STARTARTIST':
            return {
                ...state,
                loading: true,
            };
        case 'ARTISTDATAFAIL':
            return {
                ...state,
                loading: null,
            };
        case 'ARTISTDATASUCCESS':
            return {
                ...state,
                loading: null,
            };
        case 'GETARTISTDATA':
            return {
                ...state,
                loading: null,
                artistInfo:action.artistInfo
            };
        default:
            return state;
    }
};

export default artistDetails;