import React from "react";
import { Routes, Route } from "react-router-dom";
import { ScrollToTop } from "../components/ScrollToTop";
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Home } from './Home';
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TermsOfServices } from "./TermsOfServices";

export const Layout = () => {
    return (
        <>
            {/* Included the scrollToTop so that when we redirect to any page page will visible from the top end */}
            <ScrollToTop />

            {/* Header Section */}
            <Header />

            {/* Main Page Content Section */}
            <div className="main-content">
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route exact path="/terms-services" element={<TermsOfServices />} />
                </Routes>
            </div>

            {/* Footer Section */}
            <Footer />
        </>
    )
}
