import React, { useState } from 'react'
import AuthBackground from '../assets/images/auth-banner.jpg';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../redux/actions/auth';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ForgotPassword = () => {
  const auth = useSelector(state => state.auth);
  const { loading } = auth;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showEmailSection, setShowEmailSection] = useState(true);
  const [resetPasswordSection, setResetPasswordSection] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetOTP, setResetOTP] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const enableOtpSection = (event) => {
    event.preventDefault(); // Prevent default form submission
    if (email) {
      let data = { email }
      dispatch(actions.forgotPassword(data, setShowEmailSection, setResetPasswordSection, toast));
    } else {
      toast.warning('Please enter Email', {
        position: "top-center",
        theme: "colored",
        hideProgressBar: true
      })
    }
  }

  const handleResetPassword = (event) => {
    event.preventDefault();
    if (resetOTP && password && email) {
      const data = {
        email: email, otp: resetOTP, newPassword: password
      }
      dispatch(actions.resetPassword(data, navigate, toast));
    } else {
      toast.warning('Please enter OTP and New Password', {
        position: "top-center",
        theme: "colored",
        hideProgressBar: true
      })
    }
  }

  const handleResendOTP = (e) => {
    e.preventDefault()
    if (email) {
      const data = { email, type: 'reset' }
      dispatch(actions.resendOtp(data, navigate, toast));
    } else {
      toast.warning('Email required!', {
        position: "top-center",
        theme: "colored",
        hideProgressBar: true
      })
    }


  }

  return (
    <div className='auth-page h-screen flex items-center justify-center'>
      <ToastContainer />
      <div className="grid grid-cols-1 md:grid-cols-2 h-full w-full">
        <div className="grid-item">
          <div className="left-sec bg-theme_clr1 h-full flex items-center justify-center px-5 py-12 lg:p-[50px] 2xl:p-[69px]">
            <div className="wrapper">
              <div type='button' className="logo-wrapper mb-6 md:mb-7 xl:mb-[33px] cursor-pointer" onClick={() => navigate('/')}>
                <img src={require('../assets/images/logo.png')} alt="Logo" className="logo max-w-[145px] 2xl:max-w-[200px] mx-auto" />
                <div className='line-bar bg-[#EDECF2] opacity-[15%] h-[1px] w-[64px] mx-auto'></div>
              </div>

              {/* Email Section */}
              {showEmailSection && (
                <form onSubmit={enableOtpSection} className='font-manrope w-full max-w-[600px] mx-auto'>
                  <div className='heading text-white text-xl lg:text-[25px] xl:text-[32px] 2xl:text-[54px] font-medium text-center mb-6 xl:mb-8 leading-tight'>Forgot Password</div>
                  <div className='text-white text-lg md:text-xl 2xl:text-2xl font-bold mb-4 md:mb-7'>Forgot your password?</div>
                  <p className='text-white text-base mb-7 2xl:mb-[38px]'>Enter the email address associated with your account and we'll send you a link to reset your password.</p>
                  <div className="input-cover mb-6 2xl:mb-[51px]">
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className='form-control' placeholder='Email' autoComplete='off' required />
                  </div>
                  <div className="btn-wrapper">
                    <button type='submit' className='loader-button w-full bg-theme_clr2 text-white font-medium flex items-center justify-center gap-[4.5px] rounded-full px-5 py-[11.5px]' disabled={loading}>
                      {loading ? (
                        <span className="loader"> </span>
                      ) : (
                        ''
                      )}
                      Submit
                    </button>
                  </div>
                </form>
              )}

              {/* OTP Section */}
              {resetPasswordSection && (
                <form onSubmit={handleResetPassword} className='font-manrope w-full max-w-[600px] mx-auto'>
                  <div className='heading text-white text-xl lg:text-[25px] xl:text-[32px] 2xl:text-[54px] font-medium text-center mb-6 xl:mb-8 leading-tight'>Reset Password</div>
                  <p className='text-white text-base mb-7 2xl:mb-[36px]'>Password must be 8+ characters, include uppercase, lowercase, a number, and a special character (e.g., !, @, #).</p>
                  <div className="reset-password-section mb-6 2xl:mb-[51px]">
                    <div className="input-cover mb-6 2xl:mb-[51px]">
                      <input type="text" id="resetOtp" value={resetOTP} onChange={(e) => setResetOTP(e.target.value)} className='form-control' placeholder='One-Time Password (OTP)' autoComplete='off' required />
                      <div className="text-white text-sm mt-3">Didn't receive OTP? <button className='text-theme_clr2 ml-1' onClick={(e) => handleResendOTP(e)}>Resend</button></div>
                    </div>
                    <div className="input-cover relative mb-6 2xl:mb-[51px]">
                      <input type={passwordVisible ? 'text' : 'password'} id="resetPassword"
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                        title="Password must be 8+ characters, include uppercase, lowercase, a number, and a special character (e.g., !, @, #)."
                        value={password} onChange={(e) => setPassword(e.target.value)}
                        className='form-control !ps-4 !pe-12'
                        placeholder='New password' autoComplete='new-password' required />
                      <span role="button" className="text-white absolute top-1/2 right-4 -translate-y-1/2 text-secondary" onClick={togglePasswordVisibility}>
                        {passwordVisible ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}
                      </span>
                    </div>
                  </div>

                  <div className="btn-wrapper">
                    <button type='submit' className='loader-button w-full bg-theme_clr2 text-white font-medium flex items-center justify-center gap-[4.5px] rounded-full px-5 py-[11.5px]' disabled={loading}>
                      {loading ? (
                        <span className="loader"> </span>
                      ) : (
                        ''
                      )}
                      Reset Password
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <div className="grid-item">
          <div className="right-sec h-full flex items-center justify-center px-5 py-12 lg:p-[50px] 2xl:p-[69px]" style={{ backgroundImage: `url(${AuthBackground})` }}>
            <div className="overlay-wrapper bg-[#ffffff] opacity-[72%] max-w-[500px] font-manrope rounded-3xl mx-auto p-5 lg:p-6 xl:p-8">
              <div className="flex flex-wrap items-center justify-between mb-4">
                <div className="left">
                  <h5 className='text-base xl:text-lg 2xl:text-xl text-[#707070]'>Over</h5>
                  <div className="counter text-[40px] xl:text-[50px] 2xl:text-[67px] font-extrabold leading-tight">3M</div>
                  <div className="stream-on text-base xl:text-lg 2xl:text-xl text-[#707070]">streams on Spotify</div>
                </div>
                <div className="right">
                  <div className='rounded-3xl p-5 lg:p-6' style={{ backgroundColor: 'rgba(30, 215, 96, 0.16)' }}>
                    <img src={require('../assets/icons/spotify-icon.png')} alt="Spotify Icon" className='block w-[50px] xl:w-[63px]' />
                  </div>
                </div>
              </div>

              <div className="text-black text-base xl:text-lg 2xl:text-xl font-jakarta mb-6 xl:mb-8">
                "What I love most about UpNComer is the clarity it provides. I can track my progress across campaigns,
                finances, and streams—all in one place. It’s like having a full music team at my fingertips, but with more autonomy."
              </div>
              <div className="author-section flex items-center gap-4">
                <img src={require('../assets/images/karan.png')} alt="Author" className='block w-[60px] 2xl:w-[88px]' />
                <div className="author-info capitalize">
                  <div className="name text-lg xl:text-xl 2xl:text-[22px] xl:mb-1">karan</div>
                  <div className="title text-base xl:text-lg 2xl:text-xl text-[#707070]">Artist</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
