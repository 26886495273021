import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import AuthBackground from '../assets/images/auth-banner.jpg';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../redux/actions/auth';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import 'react-toastify/dist/ReactToastify.css';

export const CompleteRegistration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const auth = useSelector(state => state.auth);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [country, setCountry] = useState('');
  const [isAgreeforTermAndCOndition, setTermAndCondition] = useState(false)
  const options = useMemo(() => countryList().getData(), [])
  const { loading } = auth;
  const dispatch = useDispatch();
  // console.log(email, 'emaaaaillll000000')
  const changeHandler = value => {
    setCountry(value.label)
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    if (firstName && lastName && userName && country) {
      if (!isAgreeforTermAndCOndition) {
        toast.warning('Please agree for term and condition', {
          position: "top-center",
          theme: "colored",
          hideProgressBar: true
        })
        return
      }
      let data = { firstName, lastName, userName, country, email }
      dispatch(actions.completeRegistration(data, navigate, toast));
    } else {
      toast.warning('All fields are required', {
        position: "top-center",
        theme: "colored",
        hideProgressBar: true
      })
    }


  };
  return (
    <div className='auth-page h-screen flex items-center justify-center'>
      <ToastContainer />
      <div className="grid grid-cols-1 md:grid-cols-2 h-full w-full">
        <div className="grid-item">
          <div className="left-sec bg-theme_clr1 h-full flex items-center justify-center text-center px-5 py-12 lg:p-[50px] 2xl:p-[69px]">
            <div className="wrapper">
              <div type='button' className="logo-wrapper mb-6 md:mb-7 xl:mb-[33px] cursor-pointer" onClick={()=>navigate('/')}>
                <img src={require('../assets/images/logo.png')} alt="Logo" className="logo max-w-[145px] 2xl:max-w-[200px] mx-auto" />
                <div className='line-bar bg-[#EDECF2] opacity-[15%] h-[1px] w-[64px] mx-auto'></div>
              </div>
              <form onSubmit={handleSubmit} action="" className='font-manrope w-full max-w-[600px] mx-auto'>
                <div className='heading text-white text-xl lg:text-[25px] xl:text-[32px] 2xl:text-[54px] font-medium mb-5 md:mb-6 xl:mb-8 leading-tight'>Complete Registration</div>
                <div className="input-cover mb-[18px]">
                  <input type="text" className='form-control' placeholder='First Name'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="input-cover mb-[18px]">
                  <input type="text" className='form-control' placeholder='Last Name'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)} />
                </div>
                <div className="input-cover mb-[18px]">
                  <input type="text" className='form-control' placeholder='User Name'
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)} />
                </div>
                <div className="input-cover mb-[35px]">
                  <Select className='custom-select placeholder:text-[red]' options={options} value={country} onChange={changeHandler} placeholder='Select country' />
                  {/* <input type="text" className='form-control' placeholder='Country'
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  /> */}
                </div>
                <div className="check-wrapper flex items-start gap-3 mb-6 2xl:mb-[48px]">
                  <input type="checkbox" checked={isAgreeforTermAndCOndition} onChange={() => setTermAndCondition(!isAgreeforTermAndCOndition)} id='termsConditionCheck' className='form-check' />
                  <label htmlFor="termsConditionCheck" className='text-white text-left leading-normal'>I agree to the <Link to={''} className='text-theme_clr2'>Terms of Service</Link> and being sent latest updates related to my account.</label>
                </div>
                <div className="btn-wrapper">
                  <button type='submit' className='loader-button w-full bg-theme_clr2 text-white font-medium flex items-center justify-center gap-[4.5px] rounded-full px-5 py-[11.5px]' disabled={loading}>
                    {loading ? (
                      <span className="loader"> </span>
                    ) : (
                      ''
                    )}
                    Become an UpNComer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="grid-item">
          <div className="right-sec h-full flex items-center justify-center px-5 py-12 lg:p-[50px] 2xl:p-[69px]" style={{ backgroundImage: `url(${AuthBackground})` }}>
            <div className="overlay-wrapper bg-[#ffffff] opacity-[72%] max-w-[500px] font-manrope rounded-3xl mx-auto p-5 lg:p-6 xl:p-8">
              <div className="flex flex-wrap items-center justify-between mb-4">
                <div className="left">
                  <h5 className='text-base xl:text-lg 2xl:text-xl text-[#707070]'>Over</h5>
                  <div className="counter text-[40px] xl:text-[50px] 2xl:text-[67px] font-extrabold leading-tight">3M</div>
                  <div className="stream-on text-base xl:text-lg 2xl:text-xl text-[#707070]">streams on Spotify</div>
                </div>
                <div className="right">
                  <div className='rounded-3xl p-5 lg:p-6' style={{ backgroundColor: 'rgba(30, 215, 96, 0.16)' }}>
                    <img src={require('../assets/icons/spotify-icon.png')} alt="Spotify Icon" className='block w-[50px] xl:w-[63px]' />
                  </div>
                </div>
              </div>

              <div className="text-black text-base xl:text-lg 2xl:text-xl font-jakarta mb-6 xl:mb-8">
                "What I love most about UpNComer is the clarity it provides. I can track my progress across campaigns,
                finances, and streams—all in one place. It’s like having a full music team at my fingertips, but with more autonomy."
              </div>
              <div className="author-section flex items-center gap-4">
                <img src={require('../assets/images/karan.png')} alt="Author" className='block w-[60px] 2xl:w-[88px]' />
                <div className="author-info capitalize">
                  <div className="name text-lg xl:text-xl 2xl:text-[22px] xl:mb-1">Karan</div>
                  <div className="title text-base xl:text-lg 2xl:text-xl text-[#707070]">Artist</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
