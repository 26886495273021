import React, { useState,useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ScrollToTop } from "../components/ScrollToTop";
import { Dashboard } from "./admin/Dashboard";
import { AdminHeader } from "./admin/components/AdminHeader";
import { AdminSidebar } from "./admin/components/AdminSidebar";
import { ToastContainer, toast } from 'react-toastify';
import * as actions from '../redux/actions/index';
import { useDispatch } from "react-redux";
export const Layout2 = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getArtistInfo(toast))
    }, [dispatch])
    // State to toggle sidebar between expanded and collapsed
    const [isCollapsed, setIsCollapsed] = useState(false);

    // Handle the button click to toggle the state
    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <>
            {/* Included the scrollToTop so that when we redirect to any page page will visible from the top end */}
            <ScrollToTop />

            {/* Header Section */}
            <AdminSidebar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />

            {/* Main Page Content Section */}
            <div className={`admin-main-content ${isCollapsed ? 'md:max-w-[calc(100%-70px)]' : 'md:max-w-[calc(100%-200px)]'} ml-auto`}>
                <AdminHeader />

                <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                </Routes>
            </div>
        </>
    )
}
