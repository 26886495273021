import axios from "../../axios-config";

export const startAuth = () => {
    return {
        type: 'AUTHSTART'
    };
}

export const authFail = () => {
    return {
        type: 'AUTHFAIL'
    }
}
export const authSuccess = () => {
    return {
        type: 'AUTHSUCCESS'
    }
}

export const signup = (data, navigate, toast) => {
    return async dispatch => {
        dispatch(startAuth())
        try {
            const response = await axios.post('/user/v1/registration', data)
            toast.success(response.data.message, {
                position: "top-center",
                theme: "colored",
                hideProgressBar: true
            })
            dispatch(authSuccess())
            const load = () => { navigate('/verify-otp', { state: { email: response.data.data.email } }) }
            setTimeout(load, 2000);
        } catch (error) {
            dispatch(authFail())
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 500 || error.response.status === 401
                    || error.response.status === 403
                ) {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true
                    })
                }
            }
            else {
                toast.error(error.message, {
                    position: "top-center",
                    theme: "colored",
                    hideProgressBar: true
                })
            }
        }

    }
}

export const verifyAccount = (data, navigate, toast) => {
    return async dispatch => {
        dispatch(startAuth())
        try {
            const response = await axios.post('/user/v1/verify-account', data)
            toast.success(response.data.message, {
                position: "top-center",
                theme: "colored",
                hideProgressBar: true
            })
            dispatch(authSuccess())
            const load = () => { navigate('/complete-registration', { state: { email: response.data.data.email } }) }
            setTimeout(load, 2000);
        } catch (error) {
            dispatch(authFail())
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 500 || error.response.status === 401
                    || error.response.status === 403
                ) {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true
                    })
                }
            }
            else {
                toast.error(error.message, {
                    position: "top-center",
                    theme: "colored",
                    hideProgressBar: true
                })
            }
        }

    }
}

export const resendOtp = (data, navigate, toast) => {
    return async dispatch => {
        dispatch(startAuth())
        try {
            const response = await axios.post('/user/v1/resend-otp', data)
            toast.success(response.data.message, {
                position: "top-center",
                theme: "colored",
                hideProgressBar: true
            })
            dispatch(authSuccess()) // just for loading false
        } catch (error) {
            dispatch(authFail())
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 500 || error.response.status === 401
                    || error.response.status === 403
                ) {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true
                    })
                }
            }
            else {
                toast.error(error.message, {
                    position: "top-center",
                    theme: "colored",
                    hideProgressBar: true
                })
            }
        }

    }
}

export const completeRegistration = (data, navigate, toast) => {
    return async dispatch => {
        dispatch(startAuth());
        try {
            const response = await axios.post('/user/v1/complete-registration', data);
            toast.success(response.data.message, {
                position: "top-center",
                theme: "colored",
                hideProgressBar: true,
            });
            dispatch(authSuccess());
            localStorage.setItem('authToken', response.data.token)
            setTimeout(() => {
                navigate('/link-accounts');
            }, 2000);
        } catch (error) {
            dispatch(authFail());
            if (error.response) {
                if ([400, 401, 403, 500].includes(error.response.status)) {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                    });
                }
            } else {
                toast.error(error.message, {
                    position: "top-center",
                    theme: "colored",
                    hideProgressBar: true,
                });
            }
        }
    };
};

export const forgotPassword = (data, setShowEmailSection, setResetPasswordSection, toast) => {
    return async dispatch => {
        dispatch(startAuth())
        try {
            const response = await axios.post('/user/v1/forgot-password', data)
            toast.success(response.data.message, {
                position: "top-center",
                theme: "colored",
                hideProgressBar: true
            })
            setShowEmailSection(false);
            setResetPasswordSection(true);
            dispatch(authSuccess()) // just for loading false
        } catch (error) {
            dispatch(authFail())
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 500 || error.response.status === 401
                    || error.response.status === 403
                ) {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true
                    })
                }
            }
            else {
                toast.error(error.message, {
                    position: "top-center",
                    theme: "colored",
                    hideProgressBar: true
                })
            }
        }

    }
}

export const resetPassword = (data, navigate, toast) => {
    return async dispatch => {
        dispatch(startAuth())
        try {
            const response = await axios.post('/user/v1/reset-password', data)
            toast.success(response.data.message, {
                position: "top-center",
                theme: "colored",
                hideProgressBar: true
            })
            dispatch(authSuccess())
            const load = () => { navigate('/login') }
            setTimeout(load, 2000);
        } catch (error) {
            dispatch(authFail())
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 500 || error.response.status === 401
                    || error.response.status === 403
                ) {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true
                    })
                }
            }
            else {
                toast.error(error.message, {
                    position: "top-center",
                    theme: "colored",
                    hideProgressBar: true
                })
            }
        }

    }
}

export const loginAuth = (token, details) => {
    return {
        type: 'LOGIN',
        token: token,
        details: details
    };
}

export const login = (data, navigate, toast) => {
    return async dispatch => {
        dispatch(startAuth());
        try {
            const response = await axios.post('/user/v1/login', data);

            // Show success toast
            toast.success(response.data.message, {
                position: "top-center",
                theme: "colored",
                hideProgressBar: true
            });

            dispatch(authSuccess());
            const userData = {
                token: response.data.data.token,
                assignedArtistSongstateId: response.data.data.user.assignedArtistSongstateId
            };
            localStorage.setItem('authToken', response.data.data.token)
            setTimeout(() => {
                if (userData.assignedArtistSongstateId === '') {
                    navigate('/link-accounts', { state: { token: userData.token } });
                } else {
                    dispatch(loginAuth(userData.token, response.data.data.user));
                    navigate('/dashboard', { state: userData });
                }
            }, 2000);

        } catch (error) {
            dispatch(authFail());

            // Show error toast
            if (error.response) {
                if ([400, 401, 403, 500].includes(error.response.status)) {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true
                    });
                }
            } else {
                toast.error(error.message, {
                    position: "top-center",
                    theme: "colored",
                    hideProgressBar: true
                });
            }
        }
    };
};

export const searchArtist = (name, toast) => {
    return async dispatch => {
        dispatch(startAuth())
        try {
            const response = await axios.get(`/user/v1/search-artist`, {
                params: {
                    name
                }
            });
            dispatch(authSuccess())
            return response?.data?.data || [];
        } catch (error) {
            dispatch(authFail())
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 500 || error.response.status === 401
                    || error.response.status === 403
                ) {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true
                    })
                }
            }
            else {
                toast.error(error.message, {
                    position: "top-center",
                    theme: "colored",
                    hideProgressBar: true
                })
            }
        }

    }
}

export const AddArtist = (data, navigate, toast) => {
    return async dispatch => {
        dispatch(startAuth());
        try {
            const authCode = "Bearer " + localStorage.getItem('authToken');
            const response = await axios.post('/user/v1/assign-artist-to-user', data, {
                headers: {
                    Authorization: authCode
                }
            });
            dispatch(authSuccess());
            toast.success(response.data.message, {
                position: "top-center",
                theme: "colored",
                hideProgressBar: true,
            });

            dispatch(loginAuth(response.data.data.token, response.data.data.user));
            setTimeout(() => {
                navigate('/dashboard', { state: response.data.data.user });
            }, 2000);

        } catch (error) {
            dispatch(authFail());
            if (error.response) {
                if ([400, 401, 403, 500].includes(error.response.status)) {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                    });
                }
            } else {
                toast.error(error.message, {
                    position: "top-center",
                    theme: "colored",
                    hideProgressBar: true,
                });
            }
        }
    };
};


