import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import  auth  from './reducers/auth';
import artistDetails from './reducers/artistDetails';


const rootReducer = combineReducers({
    auth: auth,
    artistDetails:artistDetails

});

const store = configureStore({reducer:rootReducer});


export default store;