import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "../../../assets/icons/search.svg";
import BellIcon from "../../../assets/icons/bell.svg";
import hamburger from '../../../assets/icons/hamburger.svg';
import { useSelector } from "react-redux";
export const AdminHeader = () => {
    const artistDetails = useSelector(state => state.artistDetails);
    const { artistInfo } = artistDetails;
    const navigate = useNavigate();

    // For Dropdown
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    
    // For Admin Sidebar Drawer
    const toggleAdminSidebar = () => {
        document.body.classList.toggle('admin-sidebar-open');
    };

    return (
        <header className="sticky top-0 left-0 w-full z-40">
            {/* Main Navbar Section */}
            <nav className="bg-theme_clr1 py-3 xl:py-4">
                <div className="container max-w-full">
                    <div className="flex flex-wrap items-center justify-between md:justify-end gap-6 xl:gap-8 transition-transform transform">
                        <div className="md:hidden bg-theme_clr1 flex items-center justify-between">
                            <Link to={"/"}><img src={require("../../../assets/images/logo.png")} alt="Logo" className="max-w-[120px]" /></Link>
                        </div>
                        <div className="menu-wrapper hidden md:block">
                            <ul className="list-none flex flex-wrap items-center gap-5 xl:gap-[36px]">
                                <li>
                                    <Link to={"#"} className="text-sm text-white hover:text-theme_clr2 font-medium inline-block capitalize transition-all">Home</Link>
                                </li>
                                <li>
                                    <Link to={"#"} className="text-sm text-white hover:text-theme_clr2 font-medium inline-block capitalize transition-all">Support</Link>
                                </li>
                                <li>
                                    <Link to={"#"} className="text-sm text-white hover:text-theme_clr2 font-medium inline-block capitalize transition-all">Settings</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-wrap items-center gap-3 lg:gap-6 xl:gap-8">
                            <div className="hidden md:block search-box relative">
                                <span className="icon absolute start-[16px] top-1/2 transform -translate-y-1/2 z-1">
                                    <img src={SearchIcon} alt="Search Icon" className="block" />
                                </span>
                                <input type="search" className="h-[40px] w-[160px] bg-white text-base ps-[45px] pe-4 py-2 text-black placeholder:text-black focus:outline-none rounded-[12px]" placeholder="Search" />
                            </div>
                            <button type="button" onClick={() => navigate("/login")} className="bg-white text-black text-sm font-manrope font-bold px-[14px] py-[10px] rounded-[12px]">
                                <img src={BellIcon} alt="Bell Icon" />
                            </button>
                            <div className="relative inline-block text-left">
                                <button type="button" className="align-middle" aria-expanded="true" aria-haspopup="true" onClick={toggleDropdown}>
                                    <img src={artistInfo.avatar?artistInfo.avatar:require("../../../assets/images/karan.png")} alt="User" className="h-[40px] w-[40px] block rounded-full" />
                                </button>
                                {(showDropdown &&
                                    <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                        <div className="py-1" role="none">
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-2">License</a>
                                            <form method="POST" action="#" role="none">
                                                <button type="submit" className="block w-full px-4 py-2 text-left text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <button type="button" className="h-[40px] min-w-[56px] md:hidden flex items-center justify-center border border-orange rounded-full ms-auto px-5 py-3" onClick={toggleAdminSidebar}>
                                <img src={hamburger} alt="Mobile Menu" className="icon" />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}
