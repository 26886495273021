import React from 'react';
import Accordion from '../components/Accordion';

export const PrivacyPolicy = () => {
  const InformationCollection = [
    {
      title: "What types of information do we collect?",
      content: [
        <>
          <p className='mb-4'>We collect personal information that you provide to us.</p>
          <p className='mb-4'>Profile information: When you create an account, we collect your name, username, and email address. You may also choose to include a profile photo, bio, or other personal details in your profile.</p>
          <p className='mb-4'>Payment information: If you purchase premium features, we collect your payment card information and billing address.</p>
          <p className='mb-4'>Communication content: We collect information that you submit through our platform, such as comments, posts, messages, and other user-generated content.</p>
          <p>Usage data: We collect information about how you interact with our platform, including your activity, device information, IP address, and other usage details.</p>
        </>
      ]
    },
    {
      title: "How do we collect your information?",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      title: "Why do we collect your information?",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    }
  ];

  const DataUse = [
    {
      title: "How do we use your information?",
      content: [
        <>
          <p className='mb-4'>We use your information for the following purposes:</p>
          <p className='mb-4'>Account management: To create and manage your user account, process transactions, and provide customer support.</p>
          <p className='mb-4'>Platform functionality: To enable features such as posting content, commenting, messaging, notifications, and other interactive elements.</p>
          <p className='mb-4'>Personalization: To customize your experience, suggest relevant content, recommend users to follow, and show targeted ads.</p>
          <p>Analytics and improvement: To analyze usage patterns, measure performance, conduct research, and enhance our platform.</p>
          <p>Communications: To send you service-related messages, updates, marketing communications, and other information about UpNComer.</p>
        </>
      ]
    },
    {
      title: "What is our legal basis for using your information?",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      title: "Do we use your information for advertising?",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    }
  ];

  const DataSharing = [
    {
      title: "Who do we share your information with?",
      content: [
        <>
          <p className='mb-4'>We may share your information with the following parties:</p>
          <p className='mb-4'>Service providers: To perform functions such as hosting, data analysis, payment processing, customer support, and other services on our behalf.</p>
          <p className='mb-4'>Users and the public: Certain information, such as your profile, posts, comments, and other user-generated content, may be visible to other users and the public.</p>
          <p className='mb-4'>Third-party partners: With your consent or as otherwise permitted by applicable law, we may share information with third-party partners for marketing, advertising, or other purposes.</p>
        </>
      ]
    },
    {
      title: "What are your privacy options?",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
      title: "Do we share your information with third parties?",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    }
  ];

  return (
    <div className='PrivacyPolicy-page py-7 md:py-9'>
      <div className="container" style={{ maxWidth: '928px' }}>
        <div className='page-heading text-theme_clr1 text-[25px] xl:text-[32px] font-spline font-bold leading-tight mb-4 md:mb-7'>Privacy Policy</div>
        <div className="search-wrapper relative mb-5 md:mb-6">
          <span className='search-icon absolute start-[18px] top-1/2 -translate-y-1/2 z-10'>
            <i className="bi bi-search text-white text-xl"></i>
          </span>
          <input type="search" className='w-full bg-theme_clr2 text-white text-base font-spline placeholder:text-white focus:outline-none rounded-xl pl-12 pr-4 py-3' placeholder='Search Privacy Policy' />
        </div>
        <div className="accordion-wrapper flex flex-col gap-5 md:gap-7">
          {/* Information Collection Section */}
          <div className="info-section">
            <h2 className="text-theme_clr1 text-lg font-spline font-bold mb-5">Information Collection</h2>
            <Accordion items={InformationCollection} />
          </div>

          {/* Data Use Section */}
          <div className="info-section">
            <h2 className="text-theme_clr1 text-lg font-spline font-bold mb-5">Data Use</h2>
            <Accordion items={DataUse} />
          </div>

          {/* Data Sharing Section */}
          <div className="info-section">
            <h2 className="text-theme_clr1 text-lg font-spline font-bold mb-5">Data Sharing</h2>
            <Accordion items={DataSharing} />
          </div>

          {/* Your Rights Section */}
          <div className="info-section">
            <h2 className="text-theme_clr1 text-lg font-spline font-bold mb-5">Your Rights</h2>
          </div>
        </div>
      </div>
    </div>
  )
}
