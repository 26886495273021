import React, { useEffect } from 'react';
import JoinNcomerBanner from '../assets/images/banner-2.jpg';
import NextArrow from '../assets/icons/next-arrow.svg';

export const Home = () => {
    useEffect(() => {
        // Add class to body
        document.body.classList.add('homepage-layout');

        // Cleanup function to remove the class when component unmounts
        return () => {
            document.body.classList.remove('homepage-layout');
        };
    }, []);

    return (
        <div className="home-page">
            {/* Main Banner Section */}
            <section className="main-banner-section">
                <div className="img-wrapper h-screen relative">
                    <img src={require('../assets/images/main-banner1.jpg')} alt="Main Banner" className="h-full min-h-[350px] object-cover w-full block" />
                    <div className="content-wrapper font-manrope absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full text-center p-4 md:px-8 xl:ps-[56px] xl:pe-[40px] pb-6">
                        <div className="heading text-outlined text-white text-[30px] md:text-[40px] xl:text-[48px] 2xl:text-[60px] font-spline font-bold leading-tight mb-3">
                            Your music team, fueling every step of your journey.
                        </div>
                        <div className="subheading bg-clip-text text-transparent bg-gradient-to-r from-[#00067E] from-40% to-[#FFFFFF] to-80% text-xl md:text-[25px] 2xl:text-[32px] font-manrope font-bold leading-tight mb-5 md:mb-8" style={{ filter: 'drop-shadow(-1px -1px 0 #FA6138)' }}>
                            Your music, your way. Take control with tools designed to elevate your journey.
                        </div>
                        <button type='button' className='inline-flex items-center justify-center gap-2 bg-theme_clr2 text-white text-xl xl:text-2xl font-manrope font-bold px-[50px] py-3 md:py-4 rounded-[12px]'>Join Now</button>
                    </div>
                </div>
            </section>

            <div className="container custom-container">
                {/* Your Artist Section */}
                <section className="your-artist-section py-12">
                    <div className="max-md:text-center grid md:grid-cols-2 gap-8 md:gap-x-[50px] xl:gap-x-[80px] 2xl:gap-x-[100px] mb-10">
                        <div className="grid-item">
                            <div className='text-xl md:text-2xl lg:text-[30px] xl:text-[35px] 2xl:text-[38px] md:leading-tight'>
                                Your music, your control.
                                UpNComer gives you the tools to
                                shape your career—designed for
                                artists, built for your success.
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className='text-2xl lg:text-[30px] xl:text-[30px] 2xl:text-[35px] leading-tight font-bold mb-3'>Why UpNComer?</div>
                            <div className="text-base xl:text-lg font-inter mb-3">
                                Our mission is to put you in full control of your
                                music career. With innovative technology and
                                personalized support, we empower you to manage
                                every aspect of your music journey, tailored to your
                                unique needs as an artist.
                            </div>
                            <div className='text-lg md:text-xl xl:text-[25px] leading-tight'>We’re driven   —  By artists, For artists.</div>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-y-9 gap-x-[30px] md:gap-x-[50px] xl:gap-x-[80px] 2xl:gap-x-[100px]">
                        <div className="info-box">
                            <div className="flex flex-col lg:flex-row items-center gap-4 md:gap-5 xl:gap-8 max-sm:text-center">
                                <div className="w-full lg:w-[50%] artist-img">
                                    <img src={require('../assets/images/marketing-manager.png')} alt="Marketing Manager" className="block sm:w-full mx-auto" />
                                </div>
                                <div className="lg:w-[50%] artist-info">
                                    <div className="artist-name text-[#0D0821] text-[22px] xl:text-[30px] font-medium capitalize leading-tight mb-2">Marketing Manager</div>
                                    <div className="artist-desc text-base xl:text-lg font-inter">Manage your marketing campaigns effortlessly with detailed analytics and insights to track your growth across platforms.</div>
                                </div>
                            </div>
                        </div>
                        <div className="info-box">
                            <div className="flex flex-col lg:flex-row items-center gap-4 md:gap-5 xl:gap-8 max-sm:text-center">
                                <div className="w-full lg:w-[50%] artist-img">
                                    <img src={require('../assets/images/data-analyst.png')} alt="Data Analyst" className="block sm:w-full mx-auto" />
                                </div>
                                <div className="lg:w-[50%] artist-info">
                                    <div className="artist-name text-[#0D0821] text-[22px] xl:text-[30px] font-medium capitalize leading-tight mb-2">Data Analyst</div>
                                    <div className="artist-desc text-base xl:text-lg font-inter">Analyze key metrics from streams, campaigns, and fan engagement. Make smarter decisions for long-term success with easy-to-understand data insights.</div>
                                </div>
                            </div>
                        </div>
                        <div className="info-box">
                            <div className="flex flex-col lg:flex-row items-center gap-4 md:gap-5 xl:gap-8 max-sm:text-center">
                                <div className="w-full lg:w-[50%] artist-img">
                                    <img src={require('../assets/images/publicist.png')} alt="Publicist" className="block sm:w-full mx-auto" />
                                </div>
                                <div className="lg:w-[50%] artist-info">
                                    <div className="artist-name text-[#0D0821] text-[22px] xl:text-[30px] font-medium capitalize leading-tight mb-2">Publicist</div>
                                    <div className="artist-desc text-base xl:text-lg font-inter">Submit your music to playlists, media outlets, and get sync placements. Maximize your reach with our industry connections.</div>
                                </div>
                            </div>
                        </div>
                        <div className="info-box">
                            <div className="flex flex-col lg:flex-row items-center gap-4 md:gap-5 xl:gap-8 max-sm:text-center">
                                <div className="w-full lg:w-[50%] artist-img">
                                    <img src={require('../assets/images/professorU.png')} alt="ProfessorU" className="block sm:w-full mx-auto" />
                                </div>
                                <div className="lg:w-[50%] artist-info">
                                    <div className="artist-name text-[#0D0821] text-[22px] xl:text-[30px] font-medium capitalize leading-tight mb-2">ProfessorU</div>
                                    <div className="artist-desc text-base xl:text-lg font-inter">Access tutorials, guides, and expert resources to grow your music career. Learn the skills you need to navigate the music industry successfully.</div>
                                </div>
                            </div>
                        </div>
                        <div className="info-box">
                            <div className="flex flex-col lg:flex-row items-center gap-4 md:gap-5 xl:gap-8 max-sm:text-center">
                                <div className="w-full lg:w-[50%] artist-img">
                                    <img src={require('../assets/images/artist-manager.png')} alt="Artist Manager" className="block sm:w-full mx-auto" />
                                </div>
                                <div className="lg:w-[50%] artist-info">
                                    <div className="artist-name text-[#0D0821] text-[22px] xl:text-[30px] font-medium capitalize leading-tight mb-2">Artist Manager</div>
                                    <div className="artist-desc text-base xl:text-lg font-inter">Oversee everything from campaigns to revenue and royalties, all in one place. Take control of your artist brand and success.</div>
                                </div>
                            </div>
                        </div>
                        <div className="info-box">
                            <div className="flex flex-col lg:flex-row items-center gap-4 md:gap-5 xl:gap-8 max-sm:text-center">
                                <div className="w-full lg:w-[50%] artist-img">
                                    <img src={require('../assets/images/amplitude-ai.png')} alt="Amplitude AI" className="block sm:w-full mx-auto" />
                                </div>
                                <div className="lg:w-[50%] artist-info">
                                    <div className="artist-name text-[#0D0821] text-[22px] xl:text-[30px] font-medium capitalize leading-tight mb-2">Amplitude AI</div>
                                    <div className="artist-desc text-base xl:text-lg font-inter">Get AI-driven suggestions for your next move—whether it’s refining a campaign or creating content. Use advanced AI to guide your music strategy.</div>
                                </div>
                            </div>
                        </div>
                        <div className="info-box">
                            <div className="flex flex-col lg:flex-row items-center gap-4 md:gap-5 xl:gap-8 max-sm:text-center">
                                <div className="w-full lg:w-[50%] artist-img">
                                    <img src={require('../assets/images/audio-engineer.png')} alt="Audio Engineer" className="block sm:w-full mx-auto" />
                                </div>
                                <div className="lg:w-[50%] artist-info">
                                    <div className="artist-name text-[#0D0821] text-[22px] xl:text-[30px] font-medium capitalize leading-tight mb-2">Audio Engineer</div>
                                    <div className="artist-desc text-base xl:text-lg font-inter">Use advanced audio mastering tools to polish your tracks and deliver studio-quality sound. Perfect your mix and master with ease.</div>
                                </div>
                            </div>
                        </div>
                        <div className="info-box">
                            <div className="flex flex-col lg:flex-row items-center gap-4 md:gap-5 xl:gap-8 max-sm:text-center">
                                <div className="w-full lg:w-[50%] artist-img">
                                    <img src={require('../assets/images/content-creator.png')} alt="Content Creator" className="block sm:w-full mx-auto" />
                                </div>
                                <div className="lg:w-[50%] artist-info">
                                    <div className="artist-name text-[#0D0821] text-[22px] xl:text-[30px] font-medium capitalize leading-tight mb-2">Content Creator</div>
                                    <div className="artist-desc text-base xl:text-lg font-inter">Design and schedule engaging social media content effortlessly. Take control of your brand image and storytelling across platforms.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Join UpNComers Section */}
            <section className="join-NComers-section bg-cover bg-center bg-no-repeat max-xl:text-center py-[80px] lg:py-[120px] xl:py-[160px]" style={{ backgroundImage: `url(${JoinNcomerBanner})` }}>
                <div className="container">
                    <div className="grid xl:grid-cols-2 items-center justify-center xl:justify-between gap-5 xl:gap-10">
                        <div className="grid-item">
                            <div className="text-white text-[30px] md:text-[45px] xl:text-[60px] font-semibold leading-none">
                                Join UpNComer
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="content-wrapper xl:max-w-[487px] xl:ms-auto">
                                <div className="subheading text-white text-lg xl:text-[25px] xl:leading-[37px] mb-[25px]">
                                    Take control of your music career and join the community of UpNComers today.
                                </div>
                                <button className="inline-flex items-center justify-center gap-4 bg-transparent hover:bg-theme_clr2 text-white border hover:border-theme_clr2 border-white text-base font-medium rounded-[12px] transition-all px-[21px] py-[9px]">
                                    JOIN UPNCOMER <img src={NextArrow} alt="Next Arrow" className='block' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
