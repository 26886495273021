import React, { useState } from 'react';

const AccordionItem = ({ title, content, isOpen, onToggle }) => {
    return (
        <div className="text-sm font-spline border-t border-[#E5E8EB] py-4">
            <div className="text-theme_clr1 flex justify-between items-center cursor-pointer" onClick={onToggle}>
                <h2 className="font-medium">{title}</h2>
                <span className='text-xl leading-none'>{isOpen ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}</span>
            </div>
            {isOpen && (
                <div className="accordion-content text-black mt-2">
                    {content}
                </div>
            )}
        </div>
    );
};

const Accordion = ({ items }) => {
    const [openIndex, setOpenIndex] = useState(0);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="accordion-wrapper mx-auto mt-5">
            {items.map((item, index) => (
                <AccordionItem
                    key={index}
                    title={item.title}
                    content={item.content}
                    isOpen={openIndex === index}
                    onToggle={() => toggleAccordion(index)}
                />
            ))}
        </div>
    );
};

export default Accordion;
