import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { Layout } from "./pages/Layout";
import { SignUp } from "./pages/SignUp";
import { CompleteRegistration } from "./pages/CompleteRegistration";
import { Login } from "./pages/Login";
import { ForgotPassword } from "./pages/ForgotPassword";
import { EnterOtp } from "./pages/EnterOtp";
import { Layout2 } from "./pages/Layout2";
import { LinkAccounts } from "./pages/LinkAccounts";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Layout />} />
        <Route path="/dashboard" element={<Layout2 />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/verify-otp" element={<EnterOtp />} />
        <Route exact path="/complete-registration" element={<CompleteRegistration />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/link-accounts" element={<LinkAccounts />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
